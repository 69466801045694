export default function url(root) {
    if (root === "#") return root;
    if (root.includes(";jsessionid")) {
        return root;
    }
    let pieces = root.split('?', 2);
    var rootParams;
    if (pieces.length === 2) {
        root = pieces[0];
        rootParams = "?" + pieces[1];
    } else {
        rootParams = "";
    }
    pieces = location.pathname.split(';');
    if (pieces.length === 2) {
        return root + ";" + pieces[1] + rootParams;
    } else {
        return root + rootParams;
    }
}
